import React, { useState, useContext, useEffect, useRef } from 'react'
import openSocket from 'socket.io-client'
import { useFormik } from 'formik'

// style / css
import './Dashboard.css'
import { Button } from '@material-ui/core'
import Drawer from '@material-ui/core/Drawer'
import { Pagination } from '@material-ui/lab'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'

// Components
import SavedCards from './Cards'
import SideNav from './SideNav'
import FilterPanel from '../../components/organisms/FilterPanel'
import GridView from '../../components/molecules/ProductCard/GridView'
import CustomSelect from '../../components/atoms/Inputs/CustomSelect'
import ListView from '../../components/molecules/ProductCard/ListView'
import Loader from '../../components/molecules/Loader'

// Context
import ProductContext from '../../context/product/productContext'
import AuthContext from '../../context/auth/authContext'
import AlertContext from '../../context/alert/alertContext'
import CommonContext from '../../context/common/commonContext'

// Common Function
import { useCustomMediaQuery } from '../../common/components'
import SEO from '../../SEO'

const useStyles = makeStyles({
    fullList: {
        width: 'auto',
    },
})

const Dashboard = (props) => {
    const {
        search_alldashboardproducts,
        getAllDashboardProducts,
        selectedCategories,
        dispatchSelectedCategories,
        wishlistArr,
        dashboardLoading,
        responseStatus,
        getAllSearchProducts,
    } = useContext(ProductContext)
    const { user } = useContext(AuthContext)
    const { phrase } = useContext(CommonContext)
    const classes = useStyles()

    const toggleBottomDrawer = (anchor, open) => (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return
        }

        setState({ ...state, [anchor]: open })
    }
    const [auctionView, setAuctionView] = useState('Grid')
    const [state, setState] = useState({
        right: false,
        bottom: false,
        data: {},
    })
    let [viewProduct, setViewProduct] = useState([])

    const pageOptions = [
        {
            value: '15',
            show: '15 Results per page',
        },
        {
            value: '30',
            show: '30 Results per page',
        },
        {
            value: '45',
            show: '45 Results per page',
        },
    ]

    const [search, setSearch] = useState({
        ajax: 1,
        sh_limit: 15,
        page: 1,
        view: 5, // this tells backend to which page to bring up, 5 - active, 4 - bids lost, 3 - buynow, 2 - bids won, 1 - watchlist
        orderby: 1,
        orderbyid: 5, // this tells backend to which page to bring up, 5 - active, 4 bids lost, 3 - buynow, 2 - bids won, 1 - watchlist
        location: [],
        state: [],
        city: [],
        category: '',
        condition: '',
        listfmt: '',
        reglist: '',
        countrylist: '',
        lottype: '',
    })
    const [dashboardFilter, setDashboardFilter] = useState({
        ajax: 1,
        sh_limit: 15,
        page: 1,
        view: 5, // this tells backend to which page to bring up, 5 - active, 4 - bids lost, 3 - buynow, 2 - bids won, 1 - watchlist
        orderby: 1,
        orderbyid: 5, // this tells backend to which page to bring up, 5 - active, 4 bids lost, 3 - buynow, 2 - bids won, 1 - watchlist
        location: [],
        state: [],
        city: [],
        category: '',
        condition: '',
        listfmt: '',
        reglist: '',
        countrylist: '',
        lottype: '',
    })

    const clearSearchFilter = () => {}

    useEffect(() => {
        getAllSearchProducts(
            {
                // active: props.match.params.type === 'active' ? 1 : 0,
                // buy_now: props.match.params.type === 'buy_now' ? 1 : 0,
                // won: props.match.params.type === 'won' ? 1 : 0,
                // lost: props.match.params.type === 'lost' ? 1 : 0,
                // watchlist: props.match.params.type === 'watchlist' ? 1 : 0,
                // count: 1,
            },
            'dashboard',
        )
        // unmount
        return () => {
            setViewProduct([])
            dispatchSelectedCategories([])
        }
    }, [])

    const initialLoad = useRef(true)

    // Dashboard Filter Changed
    useEffect(() => {
        if (!initialLoad.current) {
            //console.log('dashboard filter changed______', dashboardFilter)
            window.scrollTo(0, 0)
            getAllDashboardProducts(dashboardFilter)
        } else {
            initialLoad.current = false
        }
    }, [dashboardFilter])

    // Dashbord Products Changed
    useEffect(() => {
        console.log('search_alldashboardproducts', search_alldashboardproducts.records)
        setViewProduct(
            search_alldashboardproducts.records.length ? search_alldashboardproducts.records : [],
        )
    }, [search_alldashboardproducts])

    // View Product State Changed
    useEffect(() => {
        //console.log('[viewProduct] use Effect ', viewProduct)
        if (state.right) {
            const index = viewProduct.findIndex((s) => s.id == parseInt(state.data.id, 10))
            if (index !== -1) {
                setState({ ...state, data: viewProduct[index] })
            }
        }
    }, [viewProduct])

    useEffect(() => {
        if (responseStatus) {
            if (responseStatus.from === 'removeWatchlist') {
                if (responseStatus.status === 'success') {
                    getAllDashboardProducts(dashboardFilter)
                }
            }
        }
    }, [responseStatus])

    // Dashboard Type Changed
    useEffect(() => {
        setViewProduct([])
        dispatchSelectedCategories([])
        let pageType = 5
        switch (props.match.params.type) {
            case 'active':
                break
            case 'buy_now':
                pageType = 3
                break
            case 'won':
                pageType = 2
                break
            case 'lost':
                pageType = 4
                break
            case 'watchlist':
                pageType = 1
                break
        }
        setDashboardFilter({
            ...dashboardFilter,
            action: props.match.params.type,
            orderbyid: pageType,
            view: pageType,
            ajax: 1,
            sh_limit: 15,
            page: 1,
            orderby: 1,
            location: [],
            state: [],
            city: [],
            category: '',
            condition: '',
            listfmt: '',
            reglist: '',
            countrylist: '',
            lottype: '',
        })
    }, [props.match.params.type])

    const fromArrToStr = (arr, type, isName, isregion) => {
        return arr
            .filter((ele) => {
                if (isregion) return ele.type === type && typeof ele.country === 'undefined'
                return ele.type === type
            })
            .map((ele) => {
                return ele.filterBy
            })
            .join()
    }
    // Search Filter Changed
    const searchFilterFunc = ({ id, name, type, country, filterBy }, isclearAll) => {
        console.log('search filter changed')
        if (isclearAll) {
            dispatchSelectedCategories([])
            return
        }
        let selcat = [...selectedCategories]
        const selectedInd = selcat.findIndex((ele) => ele.id.toString() === id.toString())
        if (selectedInd >= 0) {
            selcat.splice(selectedInd, 1)
        } else {
            if (type === 'category') {
                let sleInd = selcat.findIndex((ele) => ele.type === 'category')
                if (sleInd >= 0) selcat.splice(sleInd, 1)
                let sleInd1 = selcat.findIndex((ele) => ele.type === 'sub_category')
                if (sleInd1 >= 0) selcat.splice(sleInd1, 1)
            }
            if (type === 'sub_category') {
                let sleInd = selcat.findIndex((ele) => ele.type === 'sub_category')
                if (sleInd >= 0) selcat.splice(sleInd, 1)
            }
            selcat.push({
                id,
                name,
                type: type,
                country,
                filterBy: filterBy,
            })
        }
        dispatchSelectedCategories(selcat)

        let payload = { ...dashboardFilter }
        if (selcat.length > 0) {
            let category = fromArrToStr(selcat, 'category', 0, 0)
            let sub_category = fromArrToStr(selcat, 'sub_category', 0, 0)
            let condition = fromArrToStr(selcat, 'condition', 0, 0)
            let listfmt = fromArrToStr(selcat, 'listing', 1, 0)
            let reglist = fromArrToStr(selcat, 'region', 0, 1)
            let countrylist = fromArrToStr(selcat, 'region', 0, 0)
            let lottype = fromArrToStr(selcat, 'lot', 1, 0)
            payload = {
                ...dashboardFilter,
                page: 1,
                category,
                sub_category,
                condition,
                listfmt,
                reglist,
                countrylist,
                lottype,
            }
        } else {
            payload = {
                ...dashboardFilter,
                category: '',
                sub_category: '',
                condition: '',
                listfmt: '',
                reglist: '',
                countrylist: '',
                lottype: '',
            }
        }
        //console.log('NEW SEARCH: ', payload)
        setDashboardFilter(payload)
    }

    // const firstFilterRef = useRef(false)
    // useEffect(() => {
    //     if (typeof dashboardFilter !== 'undefined') {
    //         if (Object.keys(dashboardFilter).length) {
    //             //console.log('SELECTED CATEGORIES: ', selectedCategories)
    //             if (
    //                 Array.isArray(selectedCategories) &&
    //                 selectedCategories.length &&
    //                 firstFilterRef.current
    //             ) {
    //                 let category = fromArrToStr(selectedCategories, 'category', 0, 0)
    //                 let condition = fromArrToStr(selectedCategories, 'condition', 0, 0)
    //                 let listfmt = fromArrToStr(selectedCategories, 'listing', 1, 0)
    //                 let reglist = fromArrToStr(selectedCategories, 'region', 0, 1)
    //                 let countrylist = fromArrToStr(selectedCategories, 'region', 0, 0)
    //                 let lottype = fromArrToStr(selectedCategories, 'lot', 1, 0)
    //                 let payload = {
    //                     ...dashboardFilter,
    //                     page: 1,
    //                     category,
    //                     condition,
    //                     listfmt,
    //                     reglist,
    //                     countrylist,
    //                     lottype,
    //                 }
    //                 //console.log('NEW SEARCH: ', payload)
    //                 setDashboardFilter(payload)
    //             }
    //             // no filter selected
    //             else {
    //                 //console.log('No filter selected')
    //                 if (firstFilterRef.current) {
    //                     let payload = {
    //                         ...dashboardFilter,
    //                         category: '',
    //                         condition: '',
    //                         listfmt: '',
    //                         reglist: '',
    //                         countrylist: '',
    //                         lottype: '',
    //                     }
    //                     //clearDashboardFilter()
    //                     setDashboardFilter(payload)
    //                 } else {
    //                     firstFilterRef.current = true
    //                 }
    //             }
    //         }
    //     }
    // }, [selectedCategories])

    const onHandlePage = (event, value) => {
        setDashboardFilter({ ...dashboardFilter, page: value })
    }

    const viewProductRef = useRef(viewProduct)
    const userRef = useRef(user)
    useEffect(() => {
        viewProductRef.current = viewProduct
        userRef.current = user
    })

    const formik = useFormik({
        initialValues: {
            searchbar: '',
        },
    })

    const perPageChange = (event) => {
        let { value } = event.target
        let payload = {
            ...dashboardFilter,
            sh_limit: value,
            page: 1,
        }
        setDashboardFilter(payload)
    }

    const toggleDrawer = (anchor, open, data) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return
        }
        if (open) {
            setState({ ...state, [anchor]: open, data: data })
        } else {
            setState({ ...state, [anchor]: open, data: {} })
        }
    }

    const renderHeading = () => {
        switch (props.match.params.type) {
            case 'auction':
                return phrase.active
            case 'watchlist':
                return phrase.watch_list
            case 'lost':
                return phrase.bids_lost
            case 'won':
                return phrase.bids_won
            case 'buy_now':
                return phrase.buy_now
            case 'myCard':
                // return phrase.my_card
                return 'My Cards'
            default:
                return phrase.active
        }
    }

    return (
        <div className="dashboard container-fluid">
            <SEO title={`${global.site_title} | Dashboard`} />
            <div className="d-flex justify-content-start customContainer">
                <div className="dashboardLt">
                    {useCustomMediaQuery('(min-width: 1024px)') ? (
                        <>
                            <div className="respSort">
                                <SideNav />
                                {/* <div className="deskFilter">
                                    <FilterPanel
                                        setSearch={setSearch}
                                        page="dashboard"
                                        searchFilterFunc={searchFilterFunc}
                                        clearSearch={clearSearchFilter}
                                        sorts={search_alldashboardproducts.sorts}
                                        search={search}
                                        from={1}
                                        products={search_alldashboardproducts.totalCategory}
                                    />
                                </div> */}
                            </div>
                        </>
                    ) : (
                        <Drawer
                            anchor="left"
                            open={state['left']}
                            className="responsiveFilterDrawer"
                            onClose={toggleBottomDrawer('left', false)}
                            onOpen={toggleBottomDrawer('left', true)}
                        >
                            <Button
                                className="headDrawerClose headerBoardClose"
                                onClick={toggleBottomDrawer('left', false)}
                            >
                                <span className="material-icons">clear</span>
                            </Button>
                            <div
                                className={clsx(classes.list, 'left')}
                                role="presentation"
                                // onClick={toggleBottomDrawer('left', false)}
                                onKeyDown={toggleBottomDrawer('left', false)}
                            >
                                <div className="dashboardFilter">
                                    <SideNav />
                                    {/* <FilterPanel
                                        setSearch={setSearch}
                                        searchFilterFunc={searchFilterFunc}
                                        clearSearch={clearSearchFilter}
                                        sorts={search_alldashboardproducts.sorts}
                                        search={search}
                                        from={1}
                                        products={search_alldashboardproducts.totalCategory}
                                    /> */}
                                </div>
                            </div>
                        </Drawer>
                    )}
                </div>
                <div className="dashboardRt">
                    <div className="myAuctions">
                        <Button
                            className="toggleRespDrawer"
                            variant="outlined"
                            onClick={toggleBottomDrawer('left', true)}
                        >
                            <span className="material-icons">tune</span>
                            {phrase.filter_by}
                        </Button>
                        <div className="headerDashCnt d-flex justify-content-between align-items-center w-100 flex-wrap">
                            <div className="d-flex flex-wrap dtWrapper">
                                <h2 className="dashTitle">
                                    {renderHeading()}
                                    <span>
                                        {viewProduct.length ? (
                                            <div>
                                                <h6>
                                                    {phrase.showing}{' '}
                                                    {search_alldashboardproducts.setDisp}{' '}
                                                    {phrase.of}{' '}
                                                    {search_alldashboardproducts.totalRecords}
                                                </h6>
                                            </div>
                                        ) : null}
                                    </span>
                                </h2>
                            </div>

                            <div className="searchClassDash d-flex justify-content-end align-items-center">
                                <div className="mt-2 mb-2 mx-2 gridListToggle">
                                    <Button
                                        className={auctionView === 'Grid' ? 'active' : ''}
                                        onClick={() => setAuctionView('Grid')}
                                    >
                                        <span className="material-icons">apps</span> GRID
                                    </Button>
                                    <Button
                                        className={auctionView === 'List' ? 'active' : ''}
                                        onClick={() => setAuctionView('List')}
                                    >
                                        <span className="material-icons">view_list</span>LIST
                                    </Button>
                                </div>

                                <CustomSelect
                                    label={phrase.show_results}
                                    name="resultsPerPage"
                                    size="small"
                                    onChange={perPageChange}
                                    value={dashboardFilter.sh_limit}
                                >
                                    {pageOptions.map((opt, optindex) => (
                                        <option key={optindex} value={opt.value}>
                                            {opt.show}
                                        </option>
                                    ))}
                                </CustomSelect>
                            </div>
                        </div>

                        {dashboardLoading ? (
                            <Loader />
                        ) : viewProduct.length ? (
                            <>
                                <div className={`searchResults ${auctionView}`}>
                                    <Drawer
                                        className="rightDrawer"
                                        anchor={'right'}
                                        open={state['right']}
                                        onClose={toggleDrawer('right', false)}
                                    >
                                        {/* <ProductView data={state.data} /> */}
                                    </Drawer>
                                    {viewProduct.map((data, index) => (
                                        <React.Fragment key={index}>
                                            {auctionView === 'Grid' ? (
                                                <GridView
                                                    key={index}
                                                    data={data}
                                                    dashboard={true}
                                                    dashboardType={props.match.params.type}
                                                    favId={`searchProd_${index}`}
                                                    drawerHandler={toggleDrawer(
                                                        'right',
                                                        true,
                                                        data,
                                                    )}
                                                />
                                            ) : (
                                                <ListView
                                                    data={data}
                                                    dashboard={true}
                                                    dashboardType={props.match.params.type}
                                                    favId={`searchProd_${index}`}
                                                    drawerHandler={toggleDrawer(
                                                        'right',
                                                        true,
                                                        data,
                                                    )}
                                                />
                                            )}
                                        </React.Fragment>
                                    ))}
                                </div>
                                <div className="row mt-3">
                                    <div className="d-flex justify-content-between align-items-center flex-wrap w-100 mb-3 pagination-wrapper">
                                        <h6>
                                            {phrase.showing} {search_alldashboardproducts.setDisp}{' '}
                                            {phrase.of} {search_alldashboardproducts.totalRecords}
                                        </h6>
                                        <Pagination
                                            count={Math.ceil(
                                                search_alldashboardproducts.totalRecords /
                                                    dashboardFilter.sh_limit,
                                            )}
                                            page={dashboardFilter.page}
                                            onChange={onHandlePage}
                                            siblingCount={3}
                                            showFirstButton
                                            showLastButton
                                            boundaryCount={2}
                                        />
                                    </div>
                                </div>
                            </>
                        ) : (
                            <div
                                className={
                                    props.match.params.type == 'myCard'
                                        ? 'paddingBox mt-4'
                                        : 'np-pro-box mt-4'
                                }
                            >
                                <>
                                    {props.match.params.type == 'myCard' ? (
                                        <SavedCards />
                                    ) : (
                                        `${phrase.no_results_found}`
                                    )}
                                </>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Dashboard
