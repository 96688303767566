import React from 'react'
import { Helmet } from 'react-helmet'

const SEOTAG = ({ page, title, description, keywords, avatar }) => {
    return (
        <>
            <Helmet>
                <title>{title ? title : `${page} - ${global.site_title}`}</title>
                <meta property="og:title" content={`${title}`} />
                <meta name="description" content={`${description}`} />

                <meta property="og:description" content={`${description}`} />

                <meta name="keywords" content={`${keywords}`} />

                <meta property="og:image" content={avatar} />

                <meta property="og:image:alt" content={`${global.site_title} Logo`} />
            </Helmet>
        </>
    )
}

export default SEOTAG
