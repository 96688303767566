import React, { useContext, useState, useEffect, useRef } from 'react'
import openSocket from 'socket.io-client'
import { apiCall } from '../../common/api'
import { useHistory } from 'react-router-dom'
import 'react-image-gallery/styles/css/image-gallery.css'
import LineItemAuction from './LineItemAuction'
import SingleAuction from './SingleAuction'
import Slider from './SliderAuction'
import SearchNav from '../../components/molecules/SearchNav'
import './ProductView.css'
import { handleRedirectInternal } from '../../common/components'
import Popup from '../../components/organisms/Popup'
import PrimaryButton from '../../components/atoms/PrimaryButton'
import SecondaryButton from '../../components/atoms/SecondaryButton'
import Loader from '../../components/molecules/Loader'
// context
import CommonContext from '../../context/common/commonContext'
import AlertContext from '../../context/alert/alertContext'
import SliderAuction from './SliderAuction'
import SEOTAG from '../../SEO/AllpagesSEO'

const ProductView = (props) => {
    const { phrase, staticPages } = useContext(CommonContext)
    const { setAlert } = useContext(AlertContext)
    let [product, setProduct] = useState({})
    let [isProduct, viewProduct] = useState(false)
    let [images, setImages] = useState([])
    const history = useHistory()
    const [termsAndCondPopup, setTermsAndCondPopup] = useState(false)
    const [hideBidding, setHideBidding] = useState(false)

    const productRef = useRef(product)
    useEffect(() => {
        productRef.current = product
    })

    const changeEndTime = (data) => {
        var changeEndTimeList = data.idsarr
        if (changeEndTimeList.length > 0) {
            console.log('list found')
            // find if it's this product
            let arrIndex = changeEndTimeList.findIndex((ele) => {
                return ele.id == productRef.current.id
            })
            console.log('arr index_ ', arrIndex)
            // this product found in the list
            if (arrIndex !== -1) {
                setProduct({
                    ...productRef.current,
                    date_closed: changeEndTimeList[arrIndex].date_closed,
                })
            }
        }
    }

    useEffect(() => {
        if (props?.match?.params?.id || props?.productId) {
            // document.title = `${global.site_title} | Product`
        } else {
            // redirect to search page
            handleRedirectInternal(props.history, 'search')
        }
        const socket = openSocket(`${process.env.REACT_APP_DOMAIN}/`)
        socket.on('realtimeupdate', (data) => {
            console.log('[END TIME UPDATE] ', data)
            changeEndTime(data)
        })
        return () => {
            socket.disconnect()
        }
    }, [])
    // console.log(props?.productId, 'ghggbx')
    useEffect(() => {
        if (props?.match?.params?.id != '' || props?.productId) {
            document.title = `${global.site_title} | Product`
            getProductView(props?.match?.params?.id || props?.productId)
        }
    }, [props?.match?.params?.id, props?.productId])

    const attachmentHandler = (attachments) => {
        const attachmentList = attachments.map((x) => {
            if (x.type == 'doc') {
                return {
                    src: `${global.site_url}/im/images/word.svg`,
                    original: `${global.site_url}/im/images/word.svg`,
                    thumbnail: `${global.site_url}/im/images/word.svg`,
                    originalTitle: x.file_name,
                    originalAlt: 'doc',
                    w: 1200,
                    h: 900,
                }
            } else if (x.type == 'xls') {
                return {
                    src: `${global.site_url}/im/images/excel.svg`,
                    original: `${global.site_url}/im/images/excel.svg`,
                    thumbnail: `${global.site_url}/im/images/excel.svg`,
                    originalTitle: x.file_name,
                    originalAlt: 'xlsx',
                    w: 1200,
                    h: 900,
                }
            } else if (x.type == 'pdf') {
                return {
                    src: `${global.site_url}/im/images/pdf.svg`,
                    original: `${global.site_url}/im/images/pdf.svg`,
                    thumbnail: `${global.site_url}/im/images/pdf.svg`,
                    originalTitle: x.file_name,
                    originalAlt: 'pdf',
                    w: 1200,
                    h: 900,
                }
            } else {
                return {
                    src: `${global.site_url}/uploads/product/${x.file_name}`,
                    original: `${global.site_url}/uploads/product/${x.file_name}`,
                    thumbnail: `${global.site_url}/uploads/product/${x.file_name}`,
                    originalAlt: 'img',
                    w: 1200,
                    h: 900,
                }
            }
        })
        setImages(attachmentList)
    }
    // download manifest file
    const downloadFile = async () => {
        // update download
        apiCall('post', 'downloadupdate', { projectid: product.id }, '', 'product')
        if (product.auctiontype == 'lineitem') {
            if (product.buyer_manifest_file == '') {
                let file = await apiCall('get', `download-csvdata/${product.id}`, '', '', 'product')

                // update product's buyer manifest file
                let tempProduct = { ...product }
                tempProduct.buyer_manifest_file = file.data.fileName
                setProduct(tempProduct)
                // if (!file.filePath) {
                //     return downloadFile()
                // }
                window.location.href = `${global.site_url}${file.data.filePath}`
            } else {
                window.location.href = `${global.site_url}/uploads/product_csv/${product.buyer_manifest_file}`
            }
        } else {
            window.location.href = `${global.site_url}/uploads/product_csv/${product.document}`
        }
    }
    const getProductView = async (id) => {
        try {
            const res = await apiCall(
                'get',
                `view${localStorage.getItem('token') ? '' : '_new'}/${id}`,
                '',
                '',
                'product',
            )
            console.log('GET PRODUCT VIEW: ', res.data)
            if (res.data.status === 'success') {
                let data = res.data.data
                if (Object.keys(data).length > 0) {
                    let tempPhrase = { ...phrase }
                    let prod = { ...data.project }

                    if (prod.auctiontype == 'live') prod.next_bid = data.next_bid
                    else prod.next_bid = data.boxvalueincrement
                    prod.csvResult = data.csvsresults
                    prod.csvLength = data.csvtlength
                    prod.csvData = data.csvdata
                    prod.csvDataRaw = data.csvdataraw
                    prod.faqContent = data.faqcontent.for_english
                    prod.bidMessage = data.bidtopstatus
                    prod.buyerPrice = data.bidderproxyant
                    prod.incrementAmt = data.incrementamt
                    prod.userHasBid = data.cbidnot
                    prod.blindIncrementVal = data.boxvalueincrementabsolutevalue
                    prod.csvfile = ''
                    prod.isNotCsvBid = data.isnancsv
                    prod.isWatchlisted = data.startwatch
                    prod.bidInstruction = data.bidinstructcontent
                    prod.currencyexist = data.currencyexist
                    prod.currenciesavailable = data.currenciesavailable
                    prod.isclosed = data.isclosed
                    prod.bid_count = data.bid_count
                    if (prod.auctiontype == 'lineitem') {
                        prod.dwBidStatus = data.dwBidStatus
                        //re-format linedata json
                        prod.linedata = JSON.parse(
                            prod.linedata.replace(/\\"/g, '"').replace(/(?:\\[rn]|[\r\n]+)+/g, ' '),
                        )
                        //console.log(prod.linedata)
                    } else {
                        // live/blind auction
                        prod.companyHasBid = data.companynot
                    }
                    attachmentHandler(data.attachmentlist)
                    if (prod.desc_conditionformat == 0) {
                        let key = `${prod.condition}_cond_content`
                        prod.desc_condition = tempPhrase[key]
                    } else if (prod.desc_conditionformat == 1) {
                        prod.desc_condition =
                            tempPhrase[`${prod.condition}_cond_content`] + prod.desc_condition
                    }
                    console.log('PRODUCT____________', prod)
                    setProduct(prod)
                    viewProduct(true)
                    if (prod.previousacceptedterms == 1 && prod.termsformat == 1) {
                        setTermsAndCondPopup(true)
                    }
                }
            } else if (res.data.status === 'error') {
                setAlert(phrase.something_went_wrong, 'error')
                history.push('/search')
            }
            // this user was not allowed to see this product => redirect to search page
            else {
                history.push('/search')
            }
        } catch (err) {
            console.log(err)
            setAlert(phrase.something_went_wrong, 'error')
            history.push('/search')
        }
    }
    const termsAndCondCancelClickHandler = () => {
        setAlert('You must Accept Terms and Condition to Bid on Product')
        setTermsAndCondPopup(false)
        setHideBidding(true)
    }

    const termsAndCondAcceptClickHandler = async () => {
        let payload = {
            projectid: product.id,
        }
        const res = await apiCall('post', '', payload, '', 'product/TermsAccepted')
        if (res.data.msg == 'success') {
            setTermsAndCondPopup(false)
            setHideBidding(false)
        }
    }

    return (
        <>
            {/* <SearchNav handleKeyDown={() => {}} /> */}
            {props.slider ? (
                isProduct ? (
                    product.auctiontype == 'lineitem' ? (
                        <LineItemAuction
                            product={product}
                            images={images}
                            slider={props.slider}
                            downloadFile={downloadFile}
                            setProduct={setProduct}
                            getProductView={getProductView}
                        />
                    ) : (
                        <SingleAuction
                            hideBidding={hideBidding}
                            product={product}
                            images={images}
                            slider={props.slider}
                            downloadFile={downloadFile}
                            setProduct={setProduct}
                            getProductView={getProductView}
                        />
                    )
                ) : (
                    <Loader />
                )
            ) : (
                <>
                    {isProduct ? (
                        <>
                            <SEOTAG
                                title={`${product.title}|Ship Cycle Auctions`}
                                page={'Product'}
                                description={product.description}
                                avatar={`${global.site_url}/uploads/product/${product.avatar}`}
                                keywords={`${product.title
                                    .split(' ')
                                    .join(',')},auction,lot,electronics,fashion,motorparts,car`}
                            ></SEOTAG>
                            {product.auctiontype == 'lineitem' ? (
                                <LineItemAuction
                                    product={product}
                                    images={images}
                                    downloadFile={downloadFile}
                                    setProduct={setProduct}
                                ></LineItemAuction>
                            ) : (
                                <SingleAuction
                                    hideBidding={hideBidding}
                                    product={product}
                                    images={images}
                                    downloadFile={downloadFile}
                                    setProduct={setProduct}
                                    getProductView={getProductView}
                                />
                            )}
                        </>
                    ) : (
                        <Loader />
                    )}
                </>
            )}

            <Popup
                open={termsAndCondPopup}
                handleClose={termsAndCondCancelClickHandler}
                modaltitle={phrase.terms_conditions}
                disableBackdropClick
                footer={
                    <>
                        <PrimaryButton
                            label={phrase.confirm}
                            onClick={termsAndCondAcceptClickHandler}
                        />
                        <SecondaryButton
                            label={phrase.cancel}
                            onClick={termsAndCondCancelClickHandler}
                        />
                    </>
                }
            >
                <div dangerouslySetInnerHTML={{ __html: product.termscond }}></div>
            </Popup>
        </>
    )
}

export default ProductView
