import React from 'react'
import './App.css'
import Routes from './routes'
import { BrowserRouter as Router } from 'react-router-dom'
import { setAuthToken } from './common/api'
import { CookiesProvider } from 'react-cookie'
import AuthState from './context/auth/authState'
import CommonState from './context/common/commonState'
import UserState from './context/user/userState'
import AlertState from './context/alert/alertState'
import ProductState from './context/product/productState'
import AuctionState from './context/auction/auctionState'
import CartState from './context/cart/cartState'
import BuyerState from './context/buyer/buyerState'
import AutopltState from './context/autopilot/autopltState'
import PayState from './context/payment/payState'
import LoaderState from './context/loaders/loader.state'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import { SnackbarProvider } from 'notistack'
import Alerts from './common/alert'
import { IntercomProvider } from 'react-use-intercom'
import { GlobalStateProvider } from './context/globalState/globalStateContext'
import { HelmetProvider } from 'react-helmet-async'
import SEO from './SEO/index'

global.site_url = process.env.REACT_APP_DOMAIN
global.images_url = global.site_url + '/uploads/product/'
global.site_id = process.env.REACT_APP_SITE_ID ? process.env.REACT_APP_SITE_ID : 1
global.site_title = process.env.SITE_TITLE ? process.env.SITE_TITLE : 'Ship Cycle Auctions'
global.site_description = process.env.SITE_DESCRIPTION
    ? process.env.SITE_DESCRIPTION
    : 'E-Commerce Returns & Stock Surplus Liquidators'
global.maintainance_mode = false

if (localStorage.token) {
    setAuthToken(localStorage.token)
}

Number.prototype.toUSFormat = function (n = 2) {
    return this.toLocaleString('en-US', {
        minimumFractionDigits: n,
        maximumFractionDigits: n,
    })
}

const App = () => {
    return (
        <HelmetProvider>
            <SEO title={global.site_title} description={global.site_description} />
            <CookiesProvider>
                <GlobalStateProvider>
                    <LoaderState>
                        <CommonState>
                            <AuthState>
                                <AlertState>
                                    <UserState>
                                        <ProductState>
                                            <AuctionState>
                                                <CartState>
                                                    <PayState>
                                                        <BuyerState>
                                                            <AutopltState>
                                                                <MuiPickersUtilsProvider
                                                                    utils={MomentUtils}
                                                                >
                                                                    <SnackbarProvider maxSnack={1}>
                                                                        <div className="App container">
                                                                            <Alerts />
                                                                            <IntercomProvider
                                                                                appId={'vuova3ln'}
                                                                                autoBoot={true}
                                                                            >
                                                                                <Router>
                                                                                    <Routes />
                                                                                </Router>
                                                                            </IntercomProvider>
                                                                        </div>
                                                                    </SnackbarProvider>
                                                                </MuiPickersUtilsProvider>
                                                            </AutopltState>
                                                        </BuyerState>
                                                    </PayState>
                                                </CartState>
                                            </AuctionState>
                                        </ProductState>
                                    </UserState>
                                </AlertState>
                            </AuthState>
                        </CommonState>
                    </LoaderState>
                </GlobalStateProvider>
            </CookiesProvider>
        </HelmetProvider>
    )
}

export default App
