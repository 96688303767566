import React, { useState, useRef, useEffect, useContext } from 'react'
import { Link } from 'react-router-dom'
import { useHistory } from 'react-router-dom'

// Style / css
import './ProductCard.css'

// Components
import FavouriteCheckbox from '../../atoms/FavoriteCheckbox'
import PrimaryButton from '../../atoms/PrimaryButton'
import SecondaryButton from '../../atoms/SecondaryButton'
import Timer from '../../../common/timer'
import AddDispute from './AddDispute'
import 'react-image-gallery/styles/css/image-gallery.css'
// Context
import AuthContext from '../../../context/auth/authContext'
import CommonContext from '../../../context/common/commonContext'

// Common Function
import { dateFormatFront, titleUrlFormat } from '../../../common/components'
import { handleRedirectInternal } from '../../../common/components'
import { Button, Drawer, MenuItem } from '@material-ui/core'
import SingleAuction from '../../../views/ProductView/SingleAuction'
import SliderAuction from '../../../views/ProductView/SliderAuction'
import ProductView from '../../../views/ProductView'
import CheckoutImg from '../../../assets/svg/checkout.svg'

const Grid = (props) => {
    const commonContext = useContext(CommonContext)
    const authContext = useContext(AuthContext)
    let [images, setImages] = useState([])
    const { phrase } = commonContext
    const [pageDescription, setPageDescription] = useState(0)
    const { isAuthenticated, user } = authContext

    const [product, setProduct] = useState()
    const [disputePopup, setDisputePopup] = useState(false)
    const history = useHistory()
    const [openDrawer, setOpenDrawer] = useState(false)
    const [isOpen, setOpen] = useState(false)
    let options = {}
    useEffect(() => {
        setProduct(props.data)
    }, [props.data])

    const openDisputePopup = () => {
        setDisputePopup(true)
    }
    const closeDisputePopup = () => {
        setDisputePopup(false)
    }
    const toggleProductDrawer = () => {
        setOpenDrawer(!openDrawer)
    }
    const redirectFile = (event) => {
        if (event.target.alt !== 'img') {
            window.location.href = `${global.site_url}/uploads/product/${event.target.title}`
        } else {
            event.preventDefault()
            setOpen(true)
        }
    }
    let categories = ''
    if (product) {
        for (let i in product.multiplecategoryshow) {
            if (i == product.multiplecategoryshow.length - 1) {
                // last index
                //categories += product.multiplecategoryshow[i]
                categories += phrase[product.multiplecategoryshow[i]]
            } else {
                //categories += product.multiplecategoryshow[i] + ', '
                categories += phrase[product.multiplecategoryshow[i]] + ','
            }
        }
    }

    if (props.dashboardType == 'myCard') {
        dashboardLink = <> </>
    }

    let dashboardLink = null
    if (props.dashboard && product) {
        if (props.dashboardType == 'won' || props.dashboardType == 'buy_now') {
            dashboardLink = (
                <div>
                    <div className="mb-1">
                        <div className="twoAct">
                            <Link to={`/invoice/${product.invoice_id}`}>
                                <PrimaryButton label={phrase.view + ' ' + phrase.order} />
                            </Link>
                            {console.log(product, 'product')}
                            {product.paid == 0 ? (
                                <div className="startCheckout">
                                    <Link to={`/checkout?invoice_id=${product.invoice_id}`}>
                                        <PrimaryButton
                                            label="PAY"
                                            onClick={() => {
                                                window.scrollTo(0, 0)
                                            }}
                                        />
                                    </Link>
                                </div>
                            ) : null}
                        </div>
                    </div>

                    <div>
                        <PrimaryButton
                            onClick={() => openDisputePopup()}
                            label={phrase.add + ' ' + phrase.dispute}
                        />
                        <AddDispute
                            open={disputePopup}
                            product={product}
                            openDisputePopup={() => openDisputePopup()}
                            closeDisputePopup={() => closeDisputePopup()}
                        />
                    </div>
                </div>
            )
        }
    }

    return (
        <div className="productCardGrid">
            {product ? (
                <>
                    <div className="pcgImg">
                        {(props.dashboard &&
                            props.dashboardType != 'watchlist' &&
                            props.dashboardType != 'active') ||
                        props.completed ? null : user.isBuyer ? (
                            <FavouriteCheckbox
                                watchlisted={product.watchlistid}
                                project_id={product.id}
                                isDashboard={props.dashboard}
                            />
                        ) : null}
                        <Link onClick={toggleProductDrawer}>
                            <img
                                src={global.site_url + '/uploads/product/' + product.avatar}
                                alt={product.title}
                            />
                        </Link>
                        {props.dashboardType == 'myCard' ? (
                            <>
                                {' '}
                                <div className="d-flex justify-content-between align-items-center">
                                    <img src="/assets/images/hologram.png" />
                                    {data.brand}
                                </div>
                                <div className="credCardNum">
                                    <h2>**** **** **** {data.last4}</h2>
                                </div>
                                <div className="credCardName d-flex justify-content-between align-items-center">
                                    <div className="text-left">
                                        <span>CARD HOLDER</span>
                                        <p>{data.name}</p>
                                    </div>

                                    <div className="text-left">
                                        <span>EXPIRES</span>
                                        <p>{data.exp_month + '/' + data.exp_year}</p>
                                    </div>
                                </div>
                            </>
                        ) : (
                            ''
                        )}
                        {product.market_status == 'open' ? (
                            <div className="TimeSlot">
                                <div className="gridBidInfo d-flex justify-content-center align-items-center">
                                    {/* <p className="hasIt">{phrase.time_left}:</p> */}
                                    <p className="thisIt">
                                        <span></span>
                                        {product.date_closed ? (
                                            <Timer
                                                date_added={product.date_added}
                                                date_closed={product.date_closed}
                                                withText={1}
                                            ></Timer>
                                        ) : null}
                                    </p>
                                </div>
                            </div>
                        ) : null}

                        {props.completed && <h4 className="aucEnded">Auction Ended</h4>}
                        {isAuthenticated && (
                            <>
                                {product.bidtopstatus === 'outbid' && (
                                    <h4 className="productWinningStatus outbid">OUTBID</h4>
                                )}
                                {product.bidtopstatus === 'winner' && (
                                    <h4 className="productWinningStatus winning">WINNING</h4>
                                )}
                                {product.bidtopstatus === 'won' && (
                                    <h4 className="productWinningStatus won">WON</h4>
                                )}
                                {product.bidtopstatus === 'lost' && (
                                    <h4 className="productWinningStatus lost">LOST</h4>
                                )}
                            </>
                        )}
                    </div>
                    <h1 className="gridProdTitle">
                        <Link onClick={toggleProductDrawer}>{product.title}</Link>
                        {/* <Button onClick={toggleProductDrawer}>{product.title}</Button> */}
                    </h1>
                    <div className="gridLotDetails">
                        <p>
                            <label>{phrase.condition}</label>{' '}
                            {phrase[product.condition_language] || product.condition_name}
                        </p>
                        <p>
                            <label> {phrase.no_of_items} </label> {product.qty} {phrase.items}
                        </p>
                    </div>
                    <div className="gridLotDetails">
                        {/* <p>
                            <label> UPC/SKU </label>
                            {product.upc}
                        </p> */}
                        <p>
                            <label> {phrase.auction_type} </label>
                            {product.auctiontype == 'live'
                                ? phrase.live_auction
                                : product.auctiontype == 'lineitem'
                                ? phrase.line_item_auction
                                : phrase.blind_auction}
                        </p>
                        <p>
                            <label> {phrase.category} </label>
                            {categories}
                        </p>
                    </div>
                    {/* <div className="gridLotDetails">
                        <p>
                            <label> {phrase.lot_size} </label>
                            {phrase[product.lottype]} {phrase.lot}
                        </p>
                        <p>
                            <label> {phrase.auction_type} </label>
                            {product.auctiontype == 'live'
                                ? phrase.live_auction
                                : product.auctiontype == 'lineitem'
                                ? phrase.line_item_auction
                                : phrase.blind_auction}
                        </p>
                    </div>
                    <div className="gridLotDetails">
                        <p>
                            <label>{phrase.manufacturer}</label>
                            {product.manufacturer}
                        </p>
                        <p>
                            <label>Model</label>
                            {product.modelnumber}
                        </p>
                    </div>
                    <div className="gridLotDetails">
                        <p>
                            <label>Size</label>
                            {product.size}
                        </p>
                        <p>
                            <label>{phrase.finish}</label>
                            {product.finish}
                        </p>
                    </div>
                    <div className="gridLotDetails fr1">
                        <p>
                            <label> {phrase.location} </label>
                            {product.city}, {product.state}, {product.country}
                        </p>
                    </div> */}
                    {props.completed ||
                    (new Date() < new Date(product.date_closed) &&
                        product.market_status === 'open') ? (
                        <>
                            <div className="priceTimeCnt">
                                {product.market_status === 'open' && !props.completed ? (
                                    product.auctiontype !== 'lineitem' ? (
                                        <>
                                            {product.auctiontype == 'live' ? (
                                                <div className="gridBidInfo d-flex align-items-center starting">
                                                    <h6 className="curr">
                                                        {product.latestbid
                                                            ? phrase.current_bid
                                                            : phrase.starting_price}
                                                        :{' '}
                                                    </h6>
                                                    <p>
                                                        <span>
                                                            {product.currency_symbol}{' '}
                                                            {product.wprice}
                                                        </span>
                                                    </p>
                                                </div>
                                            ) : null}

                                            {/* To resolve spacgin issue */}
                                            {product.auctiontype != 'live' ? (
                                                <div className="gridBidInfo d-flex justify-content-between align-items-center"></div>
                                            ) : null}
                                        </>
                                    ) : (
                                        <>
                                            {/* <div className="gridBidInfo d-flex justify-content-between align-items-center">
                                                {product.market_status === 'open' &&
                                                !props.completed ? (
                                                    <>
                                                        <h6>{phrase.time_left}:</h6>
                                                        <p className="d-flex justify-content-between align-items-center">
                                                            <span></span>
                                                            {product.date_closed ? (
                                                                <Timer
                                                                    date_added={product.date_added}
                                                                    date_closed={
                                                                        product.date_closed
                                                                    }
                                                                    withText={0}
                                                                ></Timer>
                                                            ) : null}
                                                        </p>
                                                    </>
                                                ) : null}
                                            </div> */}
                                            <div className="gridBidInfo d-flex justify-content-between align-items-center"></div>
                                        </>
                                    )
                                ) : null}

                                {props.completed ? null : (
                                    <h5 className="prodEndDate">
                                        {dateFormatFront(product.date_closed)}
                                    </h5>
                                )}
                            </div>

                            <div className={`gridBidBox ${props.completed && 'ended'}`}>
                                <Link
                                    to={`/product/view/${product.id}/${titleUrlFormat(
                                        product.title,
                                    )}`}
                                >
                                    <PrimaryButton
                                        label={
                                            props.completed
                                                ? phrase.view_ended_auction
                                                : phrase.view
                                        }
                                    />
                                </Link>
                                {/* <Bidding data={product} /> */}
                            </div>
                        </>
                    ) : props.dashboard ? (
                        dashboardLink
                    ) : (
                        <>
                            {product.bidtopstatus === 'won' && (
                                <div className="actionAfterWin">
                                    {parseInt(product.buynowpaid, 10) === 1 ? (
                                        <>
                                            <SecondaryButton
                                                label={phrase.view + ' ' + phrase.order}
                                                btnSize="small"
                                                onClick={() =>
                                                    handleRedirectInternal(
                                                        history,
                                                        `invoice/${product.invoice_id}`,
                                                    )
                                                }
                                            />
                                        </>
                                    ) : (
                                        <>
                                            <PrimaryButton
                                                label="Make payment"
                                                btnSize="small"
                                                onClick={() =>
                                                    handleRedirectInternal(history, 'cart')
                                                }
                                            />
                                        </>
                                    )}
                                </div>
                            )}
                            <div className="gridBidBox">
                                <Link
                                    to={`/product/view/${product.id}/${titleUrlFormat(
                                        product.title,
                                    )}`}
                                >
                                    <PrimaryButton label={phrase.view_ended_auction} />
                                </Link>
                                {/* <Bidding data={product} /> */}
                            </div>
                        </>
                    )}
                </>
            ) : null}
            <Drawer
                className="rightDrawer productViewDrawer"
                anchor={'right'}
                open={openDrawer}
                onClose={toggleProductDrawer}
            >
                <div className="position-relative">
                    <Link onClick={toggleProductDrawer} className="closeMe">
                        <span className="material-icons">clear</span>
                    </Link>
                    {/* <div className="d-flex justify-content-between align-items-center">
                        <div>
                            <span>Lot id : {product?.id}</span>
                        </div>
                    </div> */}
                    <div className="prodInfoSlider pt-0">
                        <Link
                            to={`/product/view/${product?.id}/${titleUrlFormat(product?.title)}`}
                            className="viewFullDetails"
                        >
                            View Full Details
                        </Link>
                        <ProductView slider productId={product?.id} />
                    </div>
                </div>
            </Drawer>
        </div>
    )
}

export default Grid
